import React from 'react';

import { useHeadingTexts } from '@src/components/PageHeader/hooks';
import { AnchorLinkList } from 'shared/ui';

import styles from './MobileSubHeader.module.scss';

interface Props {
  withAnchorList?: boolean;
}

export const MobileSubHeader = ({ withAnchorList = true }: Props) => {
  const { subHeadingText } = useHeadingTexts();

  if (!withAnchorList && !subHeadingText) {
    return null;
  }

  return (
    <div className={styles.container}>
      <p className={styles.subheading}>{subHeadingText}</p>
      {withAnchorList && <AnchorLinkList />}
    </div>
  );
};
