import { Button } from '@sravni/react-design-system';
import type { Seo } from '@sravni/types/lib/seo';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { CommonCardProps } from '@src/@types/commonCardProps';
import Card from '@src/components/ProductList/components/Card';
import { isBezProtsentovPage, isProverennyeZaymyPage } from '@src/utils/routing';
import { IS_PROMO_FEATURE_ENABLED } from 'entities/Promo/lib/getPromoVariant';
import { openBottomSheetCalculatorAnalytics } from 'features/Microcredits/lib';
import { isFullDealOrganization } from 'shared/config/fullDealOrganizationIds';
import { ComponentWithDialog } from 'shared/ui/ComponentWithDialog';
import { FullDealCalculatorBottomSheet } from 'widgets/FullDealEntryPoint';
import { LightweightCard } from 'widgets/LightweightCard';

import type { LightweightCardContentSettings } from '../types';

import { isOfferWithBottomSheetCalculator } from './isOfferWithBottomSheetCalculator';

function isShowCustomActions(offer: IClientMicrocreditListItem, path: string) {
  if (isBezProtsentovPage(path)) {
    return isOfferWithBottomSheetCalculator(offer._id);
  }
  if (isProverennyeZaymyPage(path)) {
    return false;
  }

  return IS_PROMO_FEATURE_ENABLED ? isFullDealOrganization(offer.organization._id) : false;
}

interface CardProps extends CommonCardProps {
  contentSettings?: LightweightCardContentSettings;
}

export const getRenderCard = (
  cardProps: CardProps,
  path: string,
  seo: Partial<Seo>,
  isMobile: boolean,
  hasUtmLabel: boolean,
  isShowPromoOnCard: boolean,
  isOldVitrin: boolean = false,
) => {
  const CardComponent = isOldVitrin ? Card : LightweightCard;

  if (isMobile) {
    const withCustomActions = isShowCustomActions(cardProps.offer, path);

    if (withCustomActions) {
      return (
        <ComponentWithDialog
          renderComponent={(openDialog) => (
            <CardComponent
              {...cardProps}
              hasUtmLabel={hasUtmLabel}
              /**
               *  Это для обратной совместимости с Card
               */
              onContentClickCapture={(e) => {
                e.stopPropagation();

                openDialog();
                openBottomSheetCalculatorAnalytics(cardProps.offer, 'bezprotsentov');
              }}
              actionButton={
                <Button
                  variant="primary"
                  size={36}
                  block
                  onClick={(e) => {
                    e.stopPropagation();

                    openDialog();
                    openBottomSheetCalculatorAnalytics(cardProps.offer, 'bezprotsentov');
                  }}
                >
                  Получить
                </Button>
              }
              /**
               * Это для обратной совместимости с LightweightCard
               */
              onClick={(e) => {
                e.stopPropagation();

                openDialog();
                openBottomSheetCalculatorAnalytics(cardProps.offer, 'bezprotsentov');
              }}
              renderActions={() => (
                <Button variant="primary" size={36} block>
                  Получить
                </Button>
              )}
              showPromo={isShowPromoOnCard}
            />
          )}
          renderDialog={(props) => (
            <FullDealCalculatorBottomSheet path={path} heading={seo?.heading} {...props} {...cardProps} />
          )}
        />
      );
    }
  }

  return <CardComponent {...cardProps} hasUtmLabel={hasUtmLabel} showPromo={isShowPromoOnCard} />;
};
